.programSequence {
  padding-left: 0; }

.programSequence-item {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em; }
  .programSequence-item.dragging {
    z-index: 1010; }
  .programSequence-item .fields {
    display: flex;
    align-items: center;
    margin: 0em !important;
    padding: 0em !important; }
  .programSequence-item .ui.icon.button {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .programSequence-item .header {
    font-weight: bold; }
  .programSequence-item .durationInputs {
    width: 20em; }
