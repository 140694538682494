.ui.container.loginPage {
  margin-top: 1em;
  /* Mobile */
  /* Tablet */
  /* Small Monitor */
  /* Large Monitor */ }
  .ui.container.loginPage .ui.header {
    text-align: center; }
  @media only screen and (max-width: 767px) {
    .ui.container.loginPage {
      width: auto !important;
      margin-left: 1em !important;
      margin-right: 1em !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .ui.container.loginPage {
      width: 600px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ui.container.loginPage {
      width: 600px; } }
  @media only screen and (min-width: 1200px) {
    .ui.container.loginPage {
      width: 800px; } }
