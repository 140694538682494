.devices-header {
  display: flex; }
  .devices-header .ui.icon.button {
    margin-left: 0.5em; }

.device .header {
  display: flex !important;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .device .header {
      flex-direction: row; } }

.device .device-body {
  position: relative;
  margin: 0em -1em 0em -1em;
  padding: 0em 1em 1em 1em; }
  .device .device-body.blurring.dimmable.dimmed {
    border-radius: 0.5em; }
    .device .device-body.blurring.dimmable.dimmed > :not(.dimmer) {
      -webkit-filter: blur(1px) grayscale(0.7);
      filter: grayscale(0.7); }
    .device .device-body.blurring.dimmable.dimmed .ui.dimmer {
      background-color: rgba(210, 0, 0, 0.05); }

.device .ui.grid {
  margin-top: 0; }

.device .connectionState {
  font-size: 0.75em;
  font-weight: lighter; }
  @media only screen and (min-width: 768px) {
    .device .connectionState {
      margin-left: 0.75em; } }
  .device .connectionState.connected {
    color: #13d213; }
  .device .connectionState.disconnected {
    color: #d20000; }

.section--number,
.program--number {
  width: 2em; }

.section--name {
  width: 10em;
  white-space: nowrap; }

.section-state.running {
  color: green; }

.program--nextRun {
  display: inline-block;
  padding-right: 0.5em; }

.ui.modal.programEditor.editing > .content {
  min-height: 80vh; }

.ui.modal.programEditor > .header > .header.item .inline.fields {
  margin-bottom: 0; }

.runSectionForm-runButton {
  display: inline-block; }
  .runSectionForm-runButton, .runSectionForm-runButton .ui.disabled.button {
    pointer-events: auto !important; }
