.field .durationInputs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -0.5em;
  width: auto; }
  .field .durationInputs .ui.input.durationInput > input {
    width: 0 !important; }
  .field .durationInputs .ui.input.durationInput {
    padding: 0.5em;
    max-width: 100%;
    width: auto !important;
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 1; }
    .field .durationInputs .ui.input.durationInput > input {
      min-width: 4em;
      width: auto;
      flex-basis: 4em;
      flex-grow: 1;
      flex-shrink: 0; }
    .field .durationInputs .ui.input.durationInput > .label {
      min-width: 2.5em;
      width: 2.5em;
      flex: 2.5em 1;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center; }
