.app {
  padding-top: 1em;
  padding-bottom: 1em; }

.flex-horizontal-space-between {
  display: flex;
  align-items: baseline;
  justify-content: space-between; }

.flex {
  display: flex !important; }

.flex-spacer {
  flex: 1 1; }
