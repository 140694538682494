.sectionRunner .queue {
  max-height: 14em;
  height: 14em;
  overflow-y: scroll; }

.sectionRunner--pausedState {
  padding-left: 0.75em;
  font-size: 0.75em;
  font-weight: lighter; }

.sectionRunner--pausedState > .fa {
  padding-right: 0.2em; }

.sectionRun .ui.progress {
  margin: 1em 0 0 !important; }
  .sectionRun .ui.progress .bar {
    transition: none;
    min-width: 0 !important; }
